// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/cart/components/CartTray.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/cart/components/CartTray.tsx");
  import.meta.hot.lastModified = "1732299726226.8025";
}
// REMIX HMR END

/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { Fragment } from 'react';
import { Dialog, DialogTitle, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { CartItem, consumerEditable, notEditable } from '~common/components/cart/CartItem';
import { Price } from '~common/components/product/Price';
import { OrderTotals } from '~common/components/cart/OrderTotals';
import { CurrencyCode } from '~storefront/generated/graphql';
import { Button } from '~ui/Button';
import { config } from '../config';
import { ContinueShoppingFetcher } from '../fetchers/continue-shopping-fetcher';
export function CartTray({
  open,
  onClose,
  activeOrder,
  onAdjustOrderLine,
  onRemoveItem,
  onRemoveCouponCode,
  editable = false
}) {
  _s();
  const currencyCode = activeOrder?.currencyCode || CurrencyCode.Usd;
  const haveRecurringItem = React.useMemo(() => activeOrder?.lines?.some(line => line.isSubscription), [activeOrder]);
  return <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <TransitionChild as="div" enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <DialogBackdrop onClick={() => onClose(true)} className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <TransitionChild as="div" enter="transform transition ease-in-out duration-300 sm:duration-300" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-300 sm:duration-300" leaveFrom="translate-x-0" leaveTo="translate-x-full">
              <div className="w-screen h-full max-w-xl">
                <div className="h-full flex flex-col dark:bg-slate-800 bg-white shadow-xl">
                  <div className="relative h-full flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    {/* <div className="pointer-events-none absolute bottom-0 left-0 w-full h-20 md:bg-gradient-to-b md:from-transparent md:to-white/80 md:dark:to-slate-800/80"></div> */}
                    <div className="flex items-start justify-between">
                      <DialogTitle className="dark:text-primary-500 text-md font-medium text-dark-blue">
                        Shopping cart
                      </DialogTitle>
                      <div className="ml-3 h-7 flex items-center">
                        <button type="button" className="-m-2 p-2 text-gray-500 hover:text-gray-500" onClick={() => onClose(false)}>
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6 text-black dark:text-slate-300" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <p className="text-4xl font-bold mt-4 text-black dark:text-slate-400">
                      Review your order
                    </p>
                    <div className="mt-8">
                      {activeOrder?.totalQuantity ? <div className="flow-root max-h-full">
                          <ul role="list" className="-my-6 divide-y divide-gray-200 dark:divide-slate-500">
                            {(activeOrder?.lines ?? []).map((line, index) => {
                          return <CartItem key={index} detailUrl={`/products/${line.productVariant.product.slug}`} line={line} isStorefront editable={editable ? consumerEditable : notEditable} defaultToOpen={false} subscriptionEditUrl={editable ? `${config.path}/${line.id}/subscription-edit` : undefined} groupEditUrl={editable ? `${config.path}/${line.id}/group-edit` : undefined} onRemove={onRemoveItem} currencyCode={currencyCode} />;
                        })}
                          </ul>
                        </div> : <div className="flex items-center justify-center h-48 text-xl text-gray-400 dark:text-slate-400">
                          Your cart is empty
                        </div>}
                    </div>
                  </div>

                  {activeOrder?.totalQuantity ? <div className="border-t border-gray-200 dark:border-slate-500 py-6 px-4 sm:px-6">
                      {(activeOrder.discounts ?? []).map((discount, idx) => <div className="border-b border-gray-200 dark:border-slate-500 pb-2" key={`${discount.type}-${idx}`}>
                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-slate-300">
                            <p>
                              {discount.description} Promotion (
                              {activeOrder?.couponCodes})
                            </p>
                            {currencyCode && <Price priceWithTax={discount.amount ?? 0} currencyCode={currencyCode} />}
                          </div>
                        </div>)}
                      {/* <div className="flex justify-between text-base font-medium text-gray-900 dark:text-slate-300">
                        <p>Subtotal</p>
                        <p>
                          {currencyCode && (
                            <Price
                              priceWithTax={activeOrder?.subTotalWithTax ?? 0}
                              currencyCode={currencyCode}
                            />
                          )}
                        </p>
                       </div> */}
                      {/* @ts-ignore: admin vs storefront graphql gen */}
                      <OrderTotals order={activeOrder} />
                      {/* {haveRecurringItem ? (
                        <p className="mt-0.5 text-sm text-gray-500">
                          Periodic memberships can require a downpayment and, if
                          you are signing up mid cycle, may be pro-rated through
                          the end of the current cycle. <br /> Memberships
                          automatically renew.
                        </p>
                       ) : null} */}
                      {/* <p className="mt-0.5 text-sm text-gray-500">
                        Shipping will be calculated at
                        checkout.
                       </p> */}
                      <div className="mt-6 flex flex-row items-center justify-between">
                        <ContinueShoppingFetcher order={activeOrder}>
                          {({
                        loading,
                        onChangeOrderState
                      }) => <Button variant="unstyled" className="rounded-full px-6 lg:px-12 py-3 font-medium bg-slate-600 text-white hover:bg-slate-700" size="lg" isSubmitting={loading} onClick={() => {
                        onChangeOrderState();
                        onClose(false);
                      }}>
                              Continue shopping
                            </Button>}
                        </ContinueShoppingFetcher>
                        <Link to="/checkout" onClick={() => onClose(false)} className="flex justify-center items-center px-6 lg:px-12 py-3 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
                          Checkout
                          <ArrowLongRightIcon color="white" className="w-6 h-6 ml-2" />
                        </Link>
                      </div>
                    </div> : <></>}
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>;
}
_s(CartTray, "Xn5TT4jBn4UTfLOkPnNgXOTshMI=");
_c = CartTray;
var _c;
$RefreshReg$(_c, "CartTray");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;